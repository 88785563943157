import React from 'react'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { Admin, CustomRoutes, Resource } from 'react-admin'
import AuthClient from './login/auth-client'
import OurClient from './rest/our-client'
import turkishMessages from './i18n/tr'
import { useTheme } from '@mui/styles'
import ThemeProvider from './theme'
import { MotionLazy } from './components/animate/motion-lazy'
import { SettingsDrawer, SettingsProvider } from './components/settings'
import { SnackbarProvider } from './components/snackbar'
import { TownCreate, TownEdit, TownList } from './features/address/town'
import { CityCreate, CityEdit, CityList } from './features/address/city'
import { DistrictCreate, DistrictEdit, DistrictList } from './features/address/district'
import DashboardLayout from './layout/dashboard'
import { BranchCreate, BranchEdit, BranchList } from './features/branch/branch'
import { QueryClient } from 'react-query'
import { CompanyCreate, CompanyEdit, CompanyList } from './features/company/company'
import { CategoryCreate, CategoryEdit, CategoryList } from './features/category/category'
import { CustomerShow, CustomersList } from './features/customer/customer'
import { NotificationCreate, NotificationShow, NotificationsList } from './features/notification/notification'
import LoginView from './login/login-view'
import AppBannerListView, { AppBannerCreateView, AppBannerEditView } from './features/banners/app-banner'
import CategoryRearrangeView from './features/category/category-rearrange-view'
import { Route } from 'react-router-dom'
import CompaniesRearrangeView from './features/company/companies-rearrange-view'
import { OrdersList } from './features/orders/orders'
import FinanceView from './features/finance/finance-view'
import { globalConfig } from './global-config'
import { AccountView } from './features/profile/view'

const messages = {
    tr: { ...turkishMessages },
}
const i18nProvider = polyglotI18nProvider(() => messages['tr'], 'tr')


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: false,
            structuralSharing: false,
        },
        mutations: {
            retryDelay: 10000,
        },
    },
})

function App() {
    return (
        <SnackbarProvider>
            <SettingsProvider
                defaultSettings={{
                    themeMode: 'light',
                    themeDirection: 'ltr',
                    themeContrast: 'default',
                    themeLayout: 'vertical',
                    themeColorPresets: 'default',
                    themeStretch: false,
                }}
            >
                <ThemeProvider>
                    <MotionLazy>
                        <SettingsDrawer />
                        <RaAdmin />
                    </MotionLazy>
                </ThemeProvider>
            </SettingsProvider>
        </SnackbarProvider>
    )
}

const RaAdmin = () => {
    const theme = useTheme()
    return (
        <Admin
            dataProvider={OurClient(globalConfig.api.base)}
            authProvider={AuthClient()}
            loginPage={LoginView}
            title={'Nerde | Admin Panel'}
            i18nProvider={i18nProvider}
            theme={theme}
            queryClient={queryClient}
            layout={DashboardLayout}
            disableTelemetry
        >
            <Resource name={'cities'} list={CityList} create={CityCreate} edit={CityEdit} />
            <Resource name={'districts'} list={DistrictList} create={DistrictCreate} edit={DistrictEdit} />
            <Resource name={'towns'} list={TownList} create={TownCreate} edit={TownEdit} />
            <Resource name={'branches'} list={BranchList} create={BranchCreate} edit={BranchEdit} />
            <Resource name={'companies'} list={CompanyList} create={CompanyCreate} edit={CompanyEdit} />
            <Resource name={'categories'} list={CategoryList} create={CategoryCreate} edit={CategoryEdit} />
            <Resource name={'customers'} list={CustomersList} show={CustomerShow} />
            <Resource name={'orders'} list={OrdersList} />
            <Resource name={'notifications'} list={NotificationsList} create={NotificationCreate}
                      show={NotificationShow} />
            <Resource name={'app-banners'} list={AppBannerListView} edit={AppBannerEditView}
                      create={AppBannerCreateView} />
            <Resource name={'payment-types'} />
            <CustomRoutes>
                <Route path={'/categories/rearrange'} element={<CategoryRearrangeView />} />,
                <Route path={'/companies/rearrange'} element={<CompaniesRearrangeView />} />,
                <Route path={'/finance'} element={<FinanceView />} />,
                <Route path={'/profile'} element={<AccountView />} />,
            </CustomRoutes>
        </Admin>
    )
}

export default App
