// This file is generated automatically at Sun Oct  6 23:35:10 CEST 2024. Do not modify it manually.
export const globalConfig = {
   api: {
base : "https://dev-admin-api.nerdekaldi.app",
clientId : "e1a9972f-cab0-4ed8-97ce-4246708b1479",
clientSecret : "29569f20-6708-4c7c-888f-508bfbefef9e",
apiKey : "d0e1db76-0356-4423-bfa7-06d1ef27d7f9",
siteKey : "c595d5a3-11a2-4960-b376-57cc8977a036",
   }
}
